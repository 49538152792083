import React, { useState } from 'react';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import { Title } from '../components/typo';

import Episode from '../components/items/Episode';

import { programs as programsLocales } from '../datas';
import useGlobal from '../store';

import {
  Wrapper,
  QuizzWrapper,
} from './Chapter.style';

type Props = {
  chapterNumber: Number,
  images: Array<any>
};

const Program = ({ chapterNumber, images }: Props) => {
  const intl = useIntl();
  const [chapterState, setEpisodeProgress] = useGlobal(
    state => (state.chapters || []).find(c => c.number === chapterNumber),
    actions => actions.setEpisodeProgress,
  );
  const programs = programsLocales[intl.locale];

  const datas = programs.find((p => p.number === chapterNumber));

  const episodes = datas.episodes.map((episode, idx) => {
    const episodeState = chapterState && chapterState.episodes.find(e => episode.number === e.number);

    const result = {
      ...episode,
      cover: images[idx],
    };

    if (!episodeState) {
      return result;
    }

    return {
      ...result,
      progress: episodeState.progress < 1 ? episodeState.progress : false,
      watched: episodeState.progress === 1,
    };
  });

  const hasNextChapter = programs.indexOf(datas) < programs.length - 1;
  const nextToSee = episodes.findIndex(e => !e.watched);
  const chapterComplete = episodes.reduce((acc, e) => !!(acc && e.watched), true);
  
  const defaultCollapse = {
    activeEpisode: nextToSee,
  };
  
  const [collapse, setCollapse] = useState({
    ...defaultCollapse,
  });

  return (
    <Wrapper>
      <Link
        className="back-link"
        to="/app/program"
      >
        <span className="icon">&lt;</span><FormattedMessage id="program.back-program" />
      </Link>
      <Title>
        <FormattedMessage id="program.chapter" /> {chapterNumber}: {datas.title}
      </Title>
      {episodes.map((episode, idx) => {
          return (
            <Episode
              key={idx}
              title={episode.title}
              description={episode.description}
              number={episode.number}
              poster={episode.cover}
              viewed={episode.watched}
              inprogress={episode.progress}
              video={episode.video}
              collapsed={collapse.activeEpisode !== idx}
              onClick={(collapsed) => {
                if (!collapsed) {
                  return;
                }
                setCollapse({ activeEpisode: idx })
              }}
              onVideoEnded={(progress) => {
                const episodeProgress = progress > 0.8 ? 1 : progress;
                console.log('on video ended', progress);
                setEpisodeProgress(chapterNumber, episode.number, episodeProgress);

                if (episodeProgress === 1) {
                  setCollapse({ activeEpisode: collapse.activeEpisode + 1 });
                }
              }}
            />
          );
        })
      }
      {datas.quizzes && datas.quizzes.length ? (
        <QuizzWrapper>
          {chapterComplete ? (
            <>
              <h3><FormattedMessage id="program.end.title" /></h3>
              <p><FormattedMessage id="program.end.description" /></p>
            </>
          ): null}
          
          <div className="actions-container">
            <Link
              className="button"
              to={`/app/quizz?chapter=${datas.number}`}
            >
              Quiz
            </Link>
            {hasNextChapter && chapterState && chapterState.quizzDone ? (
              <Link
                className="button"
                to={`/app/chapter/${datas.number+1}/`}
              >
                <FormattedMessage id="program.next-chapter" />
              </Link>
            ): null}
          </div>
        </QuizzWrapper>
      ) : null}
    </Wrapper>
  );
};

Program.propTypes = {
  
};

export default Program;
