import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 -10px;
`;

export const QuizzWrapper = styled.div`
  margin: 40px 0;
  h3 {
    font-weight: bold;
    font-size: 20px;
    margin: 8px 0;
  }

  p {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: lighter;
    line-height: 1.3em;
    margin: 8px 0;
  }

  .button {
    display: inline-block;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;

    .button {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;