//@flow

import React from 'react';
import classNames from 'classnames';

import { Cover } from '../media';
import type { Props as CoverProps } from '../media/Cover';

import {
  Wrapper,
  EpisodeNumber,
  EpisodeTitle,
  Description,
} from './Episode.style';

type Props = {
  ...CoverProps,
  number: Number,
  collapsed: Boolean,
  onClick: () => void,
};

const Episode = ({
  title,
  number,
  viewed,
  video,
  inprogress,
  poster,
  collapsed,
  description,
  onClick,
  onVideoEnded,
  hideEpisodeNumber,
}: Props) => {
  return (
    <Wrapper
      className={classNames({
        collapsed,
      })}
    >
      {!hideEpisodeNumber ? <EpisodeNumber>Episode {number}</EpisodeNumber> : null}
      <Cover
        poster={poster}
        viewed={viewed}
        inprogress={inprogress}
        description={description}
        video={video}
        collapsed={collapsed}
        onClick={onClick}
        title={title}
        onVideoEnded={onVideoEnded}
      />
      {!collapsed ? (
        <>
          <EpisodeTitle>{title}</EpisodeTitle>
          <Description>{description}</Description>
        </>
      ): null}
    </Wrapper>
  );
};

Episode.defaultProps = {
  hideEpisodeNumber: false,
};

export default Episode;
